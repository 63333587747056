<template>
  <div class="user-logs">
    <Action-panel :isReady="isPageReady">
    </Action-panel>

    <h2 class="title-base">{{ privaciesTableTitle }}</h2>
    <div class="user-privacies">
      <TableList
        :is-ready="isPageReady"
          :headers="privaciesHeaders"
          :items="model.privacies"
        >
          <template v-slot:item.accepted="{ item }">
            <tr>
              <td>
                <v-checkbox v-model="item.accepted" disabled></v-checkbox>
              </td>
            </tr>
          </template>
      </TableList>
    </div>
    <h2 class="title-base mt-6">{{ loginsTableTitle }}</h2>
    <div class="user-logins">
      <TableList
        :is-ready="isPageReady"
        :headers="loginsHeaders"
        :items="model.logins" />
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import { userService } from "@/services/userService";
import { Utility } from "@/lib/Utility";
import Component from "vue-class-component";
import { toastService } from "@/services/toastService.js";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";

@Component({
  components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList
  },
})
export default class UserLogs extends Vue {
  isPageReady = false;
  model = [];
  search = "";

  async init() {
    try {
      this.model = await userService.readLogs(this.$route.params.id);
      this.model.privacies.forEach((element) => {
        element.updateDate = Utility.formatDateLocale(element.updateDate);
      });
      this.model.logins.forEach((element) => {
        element.lastLoginDate = Utility.formatDateLocale(element.lastLoginDate);
      });

      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  toastSuccessLabel(isEnabled) {
    isEnabled
      ? toastService.success(this.translations.success.user_disable)
      : toastService.success(this.translations.success.user_enable);
  }

  get privaciesTableTitle() {
    return this.translations.labels.user_logs_privacies_title;
  }

  get loginsTableTitle() {
    return this.translations.labels.user_logs_logins_title;
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get privaciesHeaders() {
    return [
      {
        text: this.translations.labels.user_logs_table_privacyCode,
        value: "code",
      },
      {
        text: this.translations.labels.user_logs_table_privacyVersion,
        value: "privacyVersion",
      },
      {
        text: this.translations.labels.user_logs_table_privacyUpdateDate,
        value: "updateDate",
      },
      {
        text: this.translations.labels.user_logs_table_privacyAccepted,
        value: "accepted",
      },
    ];
  }
  get loginsHeaders() {
    return [
      {
        text: this.translations.labels.user_logs_table_loginProvider,
        value: "provider",
      },
      {
        text: this.translations.labels.user_logs_table_loginLastLoginDate,
        value: "lastLoginDate",
      },
      {
        text: this.translations.labels.user_logs_table_loginEvent,
        value: "eventCode",
      },
    ];
  }
  created() {
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.users_logs_list
    );
  }
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss">
.user-logs {
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
